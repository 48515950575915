//////////////////
// Vendor Files //
//////////////////
const bugsnag = require('bugsnag-js');
window.bugsnagClient = bugsnag('5ce98c5e6e5010cbf8cdd8092523425a');

window._ = require('lodash');

window.$ = window.jQuery = require('jquery');

require('jquery.hotkeys');

// window.Vue = require('vue');

window.axios = require('axios');

window.axios.defaults.headers.common = {
    'X-CSRF-TOKEN': window.Laravel.csrfToken,
    'X-Requested-With': 'XMLHttpRequest'
};

window.Q = require('q');

window.moment = require('moment');

require('block-ui');
require('admin-lte/bootstrap/js/bootstrap.min.js');
require('admin-lte/plugins/iCheck/icheck.js');
require('admin-lte/plugins/slimScroll/jquery.slimscroll.min.js');
require('admin-lte/plugins/fastclick/fastclick.js');
require('inputmask/dist/jquery.inputmask.bundle.js');
require('admin-lte/dist/js/app.min.js');
require('jgrowl/jquery.jgrowl.min.js');
